import React from "react";
import { NavLink, Outlet } from "react-router-dom";

//image
import notification from "../../../image/Notification.png";
import down_arrow from "../../../image/down-arrow.png";
import avtar from "../../../image/Avatar-1.png";

import "../page/page.css";

let x = document?.getElementById("settingid");
let y = x?.getElementsByClassName("div-list");
for (var i = 0; i < y?.length; i++) {
  y[i]?.addEventListener("click", function () {
    let currentList = document?.getElementsByClassName("active2");
    currentList[0].className = currentList[0]?.className?.replace(
      "active2",
      ""
    );
    this.className += " active2";
  });
}

export const Setting = () => {
  return (
    <div>
      <div>
        <div className="smscode-div">
          <div>
            <h2>Setting</h2>
          </div>
          <div className="smscode-div-1">
            <div>
              <input
                name="search"
                type="search"
                placeholder="Search anything here"
                className="div-input"
              />
            </div>
            <div className="smscode-div-2">
              <input type="date" className="div-input" />
              |
              <img
                src={notification}
                alt=""
                style={{ width: 24, height: 24 }}
              />
              |
              <div className="smscode-div-1 smscode-div-2">
                <h5 className="smscode-user">Daniel Lucas</h5>
                <img src={avtar} alt="" style={{ width: 24, height: 24 }} />
                <img
                  src={down_arrow}
                  alt=""
                  style={{ width: 16, height: 16 }}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <ul className="setting-div" id="settingid">
            <li className="div-list active2">
              <NavLink to="profile"> Profile </NavLink>
            </li>
            <li className="div-list">
              <NavLink to="password"> Password </NavLink>
            </li>
            <li className="div-list">
              <NavLink to="notification">Notification</NavLink>
            </li>
          </ul>
        </div>
        <div className="setting-divided-div"></div>
        <div className="content-div-1">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
