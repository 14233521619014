import React from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

// image
import sms_code from "../../../image/sms-code.png";
import provider from "../../../image/provider.png";
import customer from "../../../image/customer.png";
import Transactions from "../../../image/transaction.png";
import setting from "../../../image/setting.png";
import logout from "../../../image/logout.png";
import home from "../../../image/home-2.png";
import mask from "../../../image/Mask.png";
import avtar from "../../../image/Type-Avatar.png";

import "./adminHome.css";

const AdminHome = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/admin");
  };
  const myFunction = () => {
    let x = document.getElementById("myTopvel");
    if (x.className === "admin-main-div") {
      x.className += "active";
    } else {
      x.className = "admin-main-div";
    }
  };
  
  let x = document.getElementById("hoverList");
  let y = x?.getElementsByClassName("admin-sub-list");
  for (var i = 0; i < y?.length; i++) {
    y[i]?.addEventListener("click", function () {
      let currentList = document?.getElementsByClassName("active1");
      currentList[0].className = currentList[0]?.className?.replace(
        "active1",
        ""
      );
      this.className += " active1";
    });
  }

  
  return (
    <div className="main-div-1">
      <nav className="main-sub-div-1">
        <div className=" main-div-2 flex-row justify-content-center p-2 gap-2">
          <img src={avtar} alt="" style={{ width: 40, height: 40 }} />
          <h5 className="text-white">Share OTP</h5>
        </div>
        <a className="toggle-button" onClick={myFunction}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </a>
        <div className="logo-divided"></div>
        <div className="admin-main-div" id="myTopvel">
          <div className="admin-list" id="hoverList">
            <ul className="" style={{ color: "#838383" }}>
              {/* <img className="active-link" alt="" /> */}
              <li className="admin-sub-list active1">
                {/* <span className="active-link"/> */}
                <img src={home} alt="" style={{ width: 24, height: 24, color: "#ffffff" }} />
                <NavLink to="dashboard"> Dashboard</NavLink>
              </li>
              <li className="admin-sub-list">
                <img src={sms_code} alt="" style={{ width: 24, height: 24, color: "white" }} />
                <NavLink to="smscode">SMS-Code</NavLink>
              </li>
              <li className="admin-sub-list">
                <img src={provider} alt="" style={{ width: 24, height: 24 }} />
                <NavLink to="provider">provider</NavLink>
              </li>
              <li className="admin-sub-list">
                <img src={customer} alt="" style={{ width: 24, height: 24 }} />
                <NavLink to="customer">Our Customer</NavLink>
              </li>
              <li className="admin-sub-list">
                <img
                  src={Transactions}
                  alt=""
                  style={{ width: 24, height: 24 }}
                />
                <NavLink to="transectin">Transactions</NavLink>
              </li>
              <li className="admin-sub-list">
                {" "}
                <img src={setting} alt="" style={{ width: 24, height: 24 }} />
                <NavLink to="setting">Setting</NavLink>
              </li>
            </ul>
          </div>
          <div className="logout-div">
            <button
              style={{ backgroundColor: "#1f222a", color: "#e5e5e5" }}
              onClick={handleClick}
            >
              <img src={logout} alt="" style={{ width: 24, height: 24 }} />
              logout
            </button>
          </div>
        </div>
      </nav>
      <hr className="col m-0 " />
      <div className="main-sub-div-2">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminHome;
