import React, { useState } from "react";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/mobile";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

import "./login.css";

import { sendOTP } from "../../../api/login";
import { Otp } from "./Otp";

const Login = (props) => {
  const navigate = useNavigate();
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  //query and mutation
  const { mutate: sendOTPMutation } = useMutation(
    "sendOTP",
    (options) => sendOTP(options),
    {
      onSuccess: () => {
        setNumber("");
      },
    }
  );

  const getOtp = async (event) => {
    event.preventDefault();
    if (number === "") {
      setError("Please enter valid phone number!");
      return;
    }
    console.log(number);
    await sendOTPMutation(number);
    setOpen(true);
  };

  const handleSubmtOtp = (event) => {
    event.preventDefault();
    alert("hello");
    setError("");
    if (otp === "") {
      setError("Please enter otp");
      return;
    } else {
      navigate("/admin/home");
      setOpen(false);
      alert("hello");
      setOtp(otp);
      console.log("success");
    }
  };

  return (
    <div className="">
      {open ? (
        <Otp />
      ) : (
        // <div className="main-div-1">
        //   <div className="login-div">
        //     <div>
        //       <h2 className="font font-div-1">SMS Protal</h2>
        //       <p className="font font-div-2">
        //         Code Has been send to +91 913** ***32
        //       </p>
        //     </div>
        //     <div className="otp-div">
        //       <input
        //         className="input-otp"
        //         type="text"
        //         maxLength={1}
        //         tabIndex={1}
        //       />
        //       <input
        //         className="input-otp"
        //         type="text"
        //         maxLength={1}
        //         tabIndex={1}
        //       />
        //       <input
        //         className="input-otp"
        //         type="text"
        //         maxLength={1}
        //         tabIndex={1}
        //       />
        //       <input
        //         className="input-otp"
        //         type="text"
        //         maxLength={1}
        //         tabIndex={1}
        //       />
        //     </div>
        //     <div className="mt-3 button-div">
        //       <button
        //         onClick={getOtp}
        //         className="button"
        //         type="submit"
        //         variant="contained"
        //       >
        //         Login
        //       </button>
        //     </div>
        //   </div>
        // </div>
        <div className="main-div">
          <form className="login-div">
            <div className="">
              <div className="login-title">
                <h2 className="font font-div-1">SMS Protal</h2>
                <p className="font font-div-2">
                  Please login your smart admin panel.
                </p>
              </div>
              <PhoneInput
                placeholder="Enter Phone Number"
                defaultCountry="IN"
                onChange={setNumber}
                value={number}
                className="input-div"
                id="phone-input"
                limitMaxLength={true}
              />
               {/* <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
                <label className="form-check-label" for="flexSwitchCheckDefault">
                Remember me
                </label>
              </div> */}
              {<span className="error">{error}</span>}
              <div className="mt-2">
                <button
                  onClick={getOtp}
                  className="button"
                  type="submit"
                  variant="contained"
                >
                  Get OTP
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Login;
