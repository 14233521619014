import { useState, useEffect, useMemo } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  where,
} from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import { query } from "firebase/database";

//image
import avtar from "../../../../image/Type-Avatar.png";
import delete_icon from "../../../../image/Auto-Horizontal.png";

import { db } from "../../../../Firebase";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let smsCodeData = [];

const Withid = (props) => {
  const [smscode, setSmscode] = useState([]); // UI

  const smsCode1 = collection(db, "sms-code");
  console.log("smsCode1", smsCode1);
  const fetchdata = async () => {
    const auth = getAuth();
    signInAnonymously(auth);

    const id = query(collection(db, "sms-code"), where("id", "!=", ""));
    await getDocs(id)
      .then(async (onsnap) => {
        const isExist = await smsCodeData.find((e) => e.id === id);
        if (!isExist) {
          const newData = onsnap?.docs?.map((doc) => ({
            data:doc.data(),
            id: doc.id,
          }));
          console.log("newData", newData);
          setSmscode(newData);
          smsCodeData = newData;
        }
      })
      .catch((err) => console.log(err));
    // console.log("qureySnapshot", qureySnapshot);
    // await qureySnapshot.forEach((doc) => {
    //   let data = doc.id;
    //   let data1 = doc.data();
    //   console.log("data", data, "data1", data1);
    //   if (!smsCodeData.includes(data, data1)) {
    //     onSnapshot(qureySnapshot, (onsnap) => {
    //       setSmscode(
    //         onsnap.docs.map((items) => ({ id: items.id, data: items.data() })),
    //         console.log("onsnap",onsnap)
    //       );
    //     });
    //     // setSmscode((arr) => {
    //     //   return [...arr, data];
    //     // });
    //     smsCodeData.push(data);
    //     console.log("smsCodeData", smscode)
    //     console.log(doc.id, "=>", doc.data());
    //   }
    // });
  };
  console.log(smsCodeData);
  useEffect(() => {
    fetchdata();
  }, []);

  // delete data
  const handleDelete = async (id) => {
    const smscodeRef = doc(db, "sms-code", id);
    await deleteDoc(smscodeRef)
      .then(() => {
        toast("smscode delete successful", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        toast.success();
      })
      .catch((error) => console.log(error.message));
    alert(id);
  };

  useEffect(() => {
    // console.log("props.search", props.search);
    searchHandle(props.search.trim());
  }, [props.search]);

  //search handle function
  const searchHandle = async (event) => {
    console.log(smsCodeData, "sms", event.id);
    const response = await smsCodeData?.filter((e) => e?.id?.match(event));
    setSmscode(response);
    // console.log("smsCodeData:=", smsCodeData);
  };

  //ui
  const withidData = useMemo(() => {
    return (
      smscode.length > 0 &&
      smscode?.map((item) => (
        <>
          <div key={item.id} className="smscode-main">
            <div className="smscode-main-1">
              <img
                src={item?.data?.image}
                alt=""
                style={{ width: 60, height: 60, borderRadius: 30 }}
              />
              <div className="sub-smscode-main-1">
                {item?.data?.code}
                <p className="smscode-p">
                  {item?.data?.name ? item?.data?.name : ""}
                </p>
              </div>
            </div>
            <div className="smscode-main-2">
              <img
                src={delete_icon}
                alt=""
                className="smscode-icon-1"
                onClick={() => handleDelete(item.id)}
              />
              <ToastContainer style={{ zIndex: 5 }} />
            </div>
          </div>
        </>
      ))
    );
  }, [smscode]);

  return (
    <div className="list">
      <div className="container">
        <div className="provider-div">{withidData}</div>
      </div>
    </div>
  );
};

export default Withid;
