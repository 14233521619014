import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../landingpage/home/home.css";

//image
import logo from "../../image/Group 3050.png";
import button from "../../image/add-icon 2.png";

const Navbar = () => {
  const [closeBotton, setCloseBotton] = useState(false);
  
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark position-fixed left-5 top-0 z-3 background p-3 w-100 ">
        <div className="container-fluid fluid">
         
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
        
          <div className="flex-row ">
            <div className="gap-2">
              <img src={logo} alt="" style={{ width: 42.85, height: 34 }} />
              <a className="navbar-brand brand " href="/">
                Share OTP
              </a>
            </div>
          </div>

          <div
            className="collapse navbar-collapse navItem "
            id="navbarNavDropdown"
          >
            <ul className=" nav navbar-nav nav-1">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " aria-current="page" to="/">
                  Overview
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/About">
                  About us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#contact-us">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <button className="Button" type="button">
              <img className="downloadButton" src={button} alt="" />
              Download App
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
