import React, { useState, useEffect, useMemo } from "react";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  setDoc,
  where,
} from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import { query } from "firebase/database";

import { db } from "../../../../Firebase";
import { useNavigate } from "react-router-dom";

import Avatar from "react-avatar-edit";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import "../../page/page.css";

//image
import avtar from "../../../../image/Type-Avatar.png";
import edit_icon from "../../../../image/Horizontal.png";
import delete_icon from "../../../../image/Auto-Horizontal.png";
import vector from "../../../../image/Vector-2.png";
import Add_icon from "../../../../image/add-icon.png";
import Close_icon from "../../../../image/close-circle.png";
import { data } from "jquery";

let smsCodeData = [];

const Withoutid = (props) => {
  // const naviget = useNavigate();
  const [smscode, setSmscode] = useState([]);
  const [selectedSmscode, setSelectedSmscode] = useState(null);
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const [search, setSearch] = useState("");
  const [searchItem, setSearchItem] = useState([]);
  const [providerSelecte, setProviderSelecte] = useState([]);
  const [selected, setSeleted] = useState({});

  const providerdata = collection(db, "provider"); //provider collection

  const fetchdata = async () => {
    const auth = getAuth();
    signInAnonymously(auth);

    const q = query(collection(db, "sms-code"), where("id", "==", ""));
    const qureySnapshot = await getDocs(q);
    await qureySnapshot.forEach((doc) => {
      let data = doc.id;
      if (!smsCodeData.includes(data)) {
        setSmscode((arr) => {
          return [...arr, data];
        });
        smsCodeData.push(data);
      }
      // console.log(doc.id, "=>", doc.data());
    });
  };

  // const handleClick = () => {
  //   alert("Please create Id");
  //   // naviget("/home/provider")
  // };

  useEffect(() => {
    fetchdata();
  }, []);

  // useEffect(() => {
  //   handleSearch();
  // }, []);

  const handleSearch = async (event) => {
    setSearch(event.target.value);
    const q = query(
      providerdata,
      where("name", ">=", `${event.target.value}`),
      where("name", "<=", `${event.target.value}\uf8ff`),
      orderBy("name")
    );
    const doc_ref = await getDocs(q);
    const response = [];
    doc_ref.forEach((items) => {
      response.push({
        id: items.id,
        data: items.data(),
      });
    });
    // alert(response);
    setSearchItem(response);
    // return () => setSearchItem("");
  };

  const providerSelected = [];
  const onSelect = (item, id) => {
    // console.log("is", item);
    const data = {
      ...item,
      selected,
    };
    providerSelected.push({ data });
    setProviderSelecte(providerSelected);
  };
  
  //selected data close button
  const handleClose = (id) => {
    const removeItem = providerSelecte.filter((items) => items.id === id);
    setProviderSelecte(removeItem);
  };

  //save provide r withid
  const handleSave = async (id) => {
    alert(id);
    console.log("hello", selected);
    const newData = doc(db, "sms-code", selectedSmscode);
    await setDoc(newData, selected);
    setVisible(false);
  };

  //delete funnction
  const handleDelete = (item) => {
    alert(item);
  };

  useEffect(() => {
    // console.log("props.search", props.search);
    searchHandle(props.search.trim());
  }, [props.search]);

  const searchHandle = async (event) => {
    const response = await smsCodeData.filter((e) => e.match(event));
    setSmscode(response);
  };

  const WithoutidData = useMemo(() => {
    return smscode.map((item, index) => (
      <>
        <div
          key={index}
          className="smscode-main"
          onClick={() => {
            setSelectedSmscode(item);
            setVisible(true);
          }}
        >
          <div className="smscode-main-1">
            <img src={avtar} alt="" style={{ width: 60, height: 60 }} />
            {item}
          </div>
          <div className="smscode-main-2">
            <img
              src={delete_icon}
              alt=""
              className="smscode-icon-1"
              onClick={() => handleDelete(item)}
            />
          </div>
        </div>
      </>
    ));
  }, [smscode]);

  return (
    <div className="list">
      <div className="container">
        <div className="provider-div">{WithoutidData}</div>
        <div
          className=" justify-content-center"
          style={{ backgroundColor: "var(--surface-900)" }}
        >
          <Dialog
            onHide={() => setVisible(false)}
            header={() => <h3 className="mt-3">Link New Provider</h3>}
            visible={visible}
            style={{ backgroundColor: "#1f222a" }}
            resizable={false}
          >
            <div>
              <div className="withoutid-search">
                <input
                  type="search"
                  placeholder="Search"
                  value={search}
                  className="div-input"
                  onChange={handleSearch}
                  style={{ width: "15rem" }}
                />
                <img
                  src={Add_icon}
                  alt=""
                  style={{ width: 30, height: 30 }}
                  onClick={() => alert("hello")}
                />
              </div>
              <div className="without-provider-main">
                {searchItem.length > 0 &&
                  searchItem.map((items) => (
                    <div
                      key={items.id}
                      className="without-provider-sub-main"
                      onClick={() => {
                        setSeleted(items);
                        onSelect(items);
                      }}
                    >
                      <>
                        <img
                          src={items.data.image}
                          alt=""
                          style={{ width: 45, height: 45, borderRadius: 20 }}
                        />
                        <h4 style={{ fontSize: 20 }}>{items.data.name}</h4>
                      </>
                    </div>
                  ))}
              </div>
              {providerSelecte.map((items) => (
                <div className="without-provider-div">
                  <div className="without-provider-list">
                    <img
                      src={items.data.data.image}
                      alt=""
                      style={{ width: 45, height: 45, borderRadius: 20 }}
                    />
                    <h2 style={{ fontSize: 30 }}>{items.data.data.name}</h2>
                  </div>
                  <div>
                    <button
                      type="reset"
                      style={{ background: "none" }}
                      onClick={() => handleClose(items.data.id)}
                    >
                      <img
                        src={Close_icon}
                        alt=""
                        style={{ width: 25, height: 25 }}
                      />
                    </button>
                    <button
                      type="submit"
                      className="without-button"
                      onClick={() => handleSave(items.data.id)}
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default Withoutid;
