import { useEffect, useMemo, useRef, useState } from "react";
// import {
//   Box,
//   Button,
//   Card,
//   CardContent,
//   Grid,
//   IconButton,
//   TextField,
//   Typography,
// } from "@mui/material";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import Avatar from "react-avatar-edit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AWS from "aws-sdk";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { db } from "../../../Firebase";

//image
import notification from "../../../image/Notification.png";
import down_arrow from "../../../image/down-arrow.png";
import avtar from "../../../image/Avatar-1.png";
import add_icon from "../../../image/add-icon.png";
import filter_icon from "../../../image/filter-icon.png";
import edit_icon from "../../../image/Horizontal.png";
import delete_icon from "../../../image/Auto-Horizontal.png";
import vector from "../../../image/Vector-2.png";
import close_icon from "../../../image/close-circle.png";

import "../page/page.css";
import { set, update } from "firebase/database";

//AWS config
AWS.config.update({
  accessKeyId: process.env.REACT_APP_accessKeyId,
  secretAccessKey: process.env.REACT_APP_secretAccessKey,
  REGION: process.env.REACT_APP_REGION,
  signatureVersion: "v4",
});

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;

let providerData = [];

const Provider = () => {
  const s3 = new AWS.S3();
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [cropImage, setCropImage] = useState(false);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState([]);
  const [selectProvider, setSelectProvider] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState(null);

  const auth = getAuth();
  signInAnonymously(auth);
  const providerdata = collection(db, "provider");

  const handleopen = () => {
    setOpen(true);
  };
 

  //search bar
  const searchHandle = async (event) => {
    setSearch(event.target.value);
    const q = query(
      providerdata,
      where("name", ">=", `${event.target.value}`),
      where("name", "<=", `${event.target.value}\uf8ff`)
    );
    const doc_ref = await getDocs(q);
    const response = [];
    doc_ref.forEach((items) => {
      response.push({
        id: items.id,
        data: items.data(),
      });
    });
    setItem(response);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (name === "") {
      alert("please enter data");
      return;
    } else {
      await addDoc(providerdata, { name, image: selectedFile })
        .then((res) => {
          console.log(res.id);
          toast("Provider Add successful");
          toast.success();
        })
        .catch((error) => console.log(error));
      setVisible(false);
      setName("");
      setCropImage(null);
      setImage("");
      setSelectedFile("");
    }
  };
  useEffect(() => {
    data();
  }, []);

  const data = () => {
    onSnapshot(providerdata, (snapshot) => {
      setItem(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })));
    });
  };

  console.log("data",item);
  const handleEditOpen = () => {
    setOpen1(true);
  };

  //update data
  const handleEdit = (id) => {
    setOpen1(true);
  };

  const handleclickSubmit = async (event) => {
    event.preventDefault();
    const providerRef = doc(db, "provider", selectProvider.id);
    await updateDoc(providerRef, {
      name: name,
      image: selectedFile,
    });
    setOpen1(false);
  };

  //delete data
  const handleDelete = async (id) => {
    const providerRef = doc(db, "provider", id);
    deleteDoc(providerRef)
      .then(() => {
        window.confirm("Are you sure ?");
        toast("provider delete successful");
        toast.success();
      })
      .catch((error) => console.log(error.message));
    alert(id);
    
  };

  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
  };

  // const handleCrop = (event) => {
  //   setSelectedFile(event.target.file[0]);
  // };
  const fileHandleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const onclos = () => {
    setSelectedFile(null);
    setImage("");
  };

  //upload image in s3
  const uploadimageToS3 = async (e) => {
    if (!file && file.size >= "120px * 120px") {
      return;
    }
    const params = {
      Bucket: S3_BUCKET,
      Key: `provider-images/${Date.now()}.${file.name}`,
      Body: file,
    };
    const { Location } = await s3.upload(params).promise();
    setSelectedFile(Location);
    setFile("");
    // console.log("uploading to s3", Location);
  };

  const saveImage = (file) => {
    if (selectedFile === "") {
      console.log("Please valid file size");
      return file.size <= "120px * 120px";
    }
    setOpen(false);
  };

  // console.log(selectProvider);
  const providerContent = useMemo(() => {
    return (
      item.length > 0 &&
      item?.map((items) => (
        <div key={items.id} className="provider-main">
          <div className="provider-main-1">
            <img
              src={items.data.image}
              style={{ width: 60, height: 60, borderRadius: 30 }}
              alt=""
            />
            <h5>{items.data.name}</h5>
          </div>
          <div className="provider-main-2">
            <img
              src={edit_icon}
              alt=""
              onClick={() => {
                handleEdit(items.id, items.data.name);
                setSelectProvider(items);
                setName(items.data.name);
                setImage(items.data.image);
              }}
              className="div-icon-1"
            />
            <img
              src={delete_icon}
              alt=""
              className="div-icon"
              onClick={() => handleDelete(items.id)}
            />
            <ToastContainer />
          </div>
        </div>
      ))
    );
  }, [item]);

  return (
    <div>
      <div>
        <div className="smscode-div">
          <div>
            <h2>Provider</h2>
          </div>
          <div className="smscode-div-1">
            <div>
              <button
                className="addbutton-div"
                type="submit"
                onClick={() => setVisible(true)}
              >
                <img src={add_icon} alt="" style={{ width: 24, height: 24 }} />
                Add New Provider
              </button>
            </div>
            <div className="smscode-div-2">
              <input type="date" className="div-input" />
              |
              <img
                src={notification}
                alt=""
                style={{ width: 24, height: 24 }}
              />
              |
              <div className="smscode-div-1 smscode-div-2">
                <h5 className="smscode-user">Daniel Lucas</h5>
                <img src={avtar} alt="" style={{ width: 24, height: 24 }} />
                <img
                  src={down_arrow}
                  alt=""
                  style={{ width: 16, height: 16 }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="sub-button-div">
          <input
            type="search"
            placeholder="Search"
            value={search}
            className="div-input"
            onChange={searchHandle}
          />
          <img src={filter_icon} alt="" style={{ width: 40, height: 40 }} />
        </div>

        {/* //show provider */}
        <div style={{ marginTop: "1rem" }}>
          <div className="provider-div">{providerContent}</div>
        </div>
      </div>

      {/* {Update provider} */}
      <Dialog
        header="Update provider"
        visible={open1}
        onHide={() => setOpen1(false)}
      >
        <form onSubmit={handleclickSubmit}>
          <div>
            <div className="dialog-contiant">
              <label className="lable-tag">Upload New Provider Image</label>
              <div className="sub-dialog-main">
                <div className="sub-dialog-image">
                  <img
                    typeof="image"
                    src={selectedFile ? selectedFile : image}
                    onChange={() => setCropImage(!cropImage)}
                    alt=""
                    style={{ width: 70, height: 70 }}
                    onClick={() => setOpen(true)}
                  />
                </div>
                <p style={{ color: "#A8A8A8" }}>
                  Image size need to be upload in
                  <br /> 120px * 120px.
                </p>
              </div>
            </div>
            <div className="dialog-input">
              <label className="lable-tag">New Provider Name :</label>
              <InputText
                autoFocus
                type="text"
                onChange={(event) => setName(event.target.value)}
                placeholder="New Provider Name"
                variant="standard"
                value={name}
                className="sub-dialogbox-input"
              />
            </div>
          </div>
          <div className="sub-dialogbox-button">
            <button className="addbutton-div" type="submit">
              Update Provider
            </button>
            <ToastContainer />
          </div>
        </form>
      </Dialog>

      {/* Add provider section */}
      <div
        className=" justify-content-center"
        style={{ backgroundColor: "var(--surface-900)" }}
      >
        <Dialog
          onHide={() => setVisible(false)}
          // onClose={handleClose}
          header={() => <h3 className="mt-3">Add New Provider</h3>}
          visible={visible}
          style={{ backgroundColor: "#1f222a" }}
          resizable={false}
        >
          <hr />
          <div>
            <div className="dialog-contiant">
              <label className="lable-tag">Upload New Provider Image</label>
              <div className="sub-dialog-main">
                <div className="sub-dialog-image">
                  <img
                    typeof="image"
                    src={selectedFile ? selectedFile : vector}
                    onChange={() => setCropImage(!cropImage)}
                    alt=""
                    style={{ width: 70, height: 70 }}
                    onClick={() => setOpen(true)}
                  />
                </div>
                <p style={{ color: "#A8A8A8" }}>
                  Image size need to be upload in
                  <br /> 120px * 120px.
                </p>
              </div>
            </div>
            <div className="dialog-input">
              <label className="lable-tag">New Provider Name :</label>
              <InputText
                autoFocus
                type="text"
                onChange={(event) => setName(event.target.value)}
                placeholder="New Provider Name"
                variant="standard"
                value={name}
                className="sub-dialogbox-input"
              />
            </div>
          </div>
          <div className="sub-dialogbox-button">
            <button
              className="addbutton-div"
              type="submit"
              onClick={handleSubmit}
            >
              Add New Provider
            </button>
            <ToastContainer />
          </div>
        </Dialog>
        <Dialog
          visible={open}
          header={() => <p>Upload profile</p>}
          onHide={() => setOpen(false)}
        >
          <div className="sub-dialog">
            <input
              type="file"
              onChange={fileHandleChange}
              accept="image/png, image/jpeg"
              name="image"
            />
            {file && (
              <div style={{ marginTop: "10px" }}>
                <button onClick={uploadimageToS3}>Upload</button>
              </div>
            )}
          </div>
          {setSelectedFile && (
            <div style={{ marginTop: "10px" }}>
              <img
                src={selectedFile}
                alt=""
                style={{ width: 120, height: 120, border: "none" }}
              />
            </div>
          )}
          <button
            type="submit"
            style={{ marginTop: 10, borderRadius: 8, width: 70 }}
            onClick={() => saveImage()}
          >
            Save
          </button>
          <ToastContainer />
        </Dialog>
      </div>
    </div>
  );
};

export default Provider;
