import React from "react";

//image
import notification from "../../../image/Notification.png";
import down_arrow from "../../../image/down-arrow.png";
import avtar from "../../../image/Avatar-1.png";
import avtar_a from "../../../image/Type-Avatar.png";
import profile_1 from "../../../image/profile-icon.png";
import profile_2 from "../../../image/profile-add.png";
import profile_3 from "../../../image/profile-2user.png";
import vector from "../../../image/Vector.png";
import elips from "../../../image/Ellipse 27.png"
import elips_1 from "../../../image/Ellipse 29.png"

import "../page/page.css";

export const Customer = () => {
  return (
    <div>
      <div className="smscode-div">
        <div>
          <h2>Customer</h2>
        </div>
        <div className="smscode-div-1 smscode-div-2">
          <input type="date" className="div-input" />
          |
          <img src={notification} alt="" style={{ width: 24, height: 24 }} />|
          <div className="smscode-div-1 smscode-div-2">
            <h5 className="smscode-user">Daniel Lucas</h5>
            <img src={avtar} alt="" style={{ width: 24, height: 24 }} />
            <img src={down_arrow} alt="" style={{ width: 16, height: 16 }} />
          </div>
        </div>
      </div>
      <div className="sub-customer-count">
        <div className="customer-div-1">
          <div className="customer-div-icon customer-icon-1">
            <img src={profile_3} alt="" style={{ width: 34, height: 34 }} />
          </div>
          <div style={{ textAlign: "left" }}>
            <h6 className="sub-password-div-p">Total Customers</h6>
            <h5>2,420</h5>
          </div>
        </div>
        <div className="customer-div-1">
          <div className="customer-div-icon customer-icon-2">
            <img src={profile_1} alt="" style={{ width: 34, height: 34 }} />
          </div>
          <div style={{ textAlign: "left" }}>
            <h6 className="sub-password-div-p">Active Customers</h6>
            <h5>1,897</h5>
          </div>
        </div>
        <div className="customer-div-1">
          <div className="customer-div-icon customer-icon-3">
            <img src={profile_2} alt="" style={{ width: 34, height: 34 }} />
          </div>
          <div style={{ textAlign: "left" }}>
            <h6 className="sub-password-div-p">New Customers</h6>
            <h5>241</h5>
          </div>
        </div>
      </div>
      <div className="customer-div-search">
        <input type="search" placeholder="Search" className="div-input" />
      </div>
      <div className="container">
        <table>
          <thead>
            <tr
             
              style={{ color: "white", backgroundColor: "#303542" }}
            >
              <th>
                NAME{" "}
                <span>
                  <img src={vector} alt="" style={{ width: 12, height: 13 }} />
                </span>
              </th>
              <th>
                DATE{" "}
                <span>
                  <img src={vector} alt="" style={{ width: 12, height: 13 }} />
                </span>
              </th>
              <th>
                Amount{" "}
                <span>
                  <img src={vector} alt="" style={{ width: 12, height: 13 }} />
                </span>
              </th>
              <th>
                OS Platfrom{" "}
                <span>
                  <img src={vector} alt="" style={{ width: 12, height: 13 }} />
                </span>
              </th>
              <th>
                STATUS {" "}
                <span>
                  <img src={vector} alt="" style={{ width: 12, height: 13 }} />
                </span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr className="tbody-div">
              <td>
                <img src={avtar_a} alt="" style={{ width: 40, height: 40 }} />
                {" "}
                Keval Pavasiya
              </td>
              <td>08/10/2023</td>
              <td>4000 USD</td>
              <td>Android</td>
              <td className="transection-status"><span><img src={elips} alt="" style={{width: 10}}/></span> Active</td>
              <td>
                <button className="customer-div-button">View</button>
              </td>
            </tr>
            <tr className="tbody-div">
              <td>
                <img src={avtar_a} alt="" style={{ width: 40, height: 40 }} />
                Keval Pavasiya
              </td>
              <td>08/10/2023</td>
              <td>4000 USD</td>
              <td>Android</td>
              <td className="transection-status-1"><span><img src={elips_1} alt="" style={{width: 10}}/></span> Pending</td>
              <td>
                <button className="customer-div-button">View</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
