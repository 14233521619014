import React, { useState } from "react";
import OtpInput from "react-otp-input";

//image
import left_arrow from "../../../image/Arrow-Left.png";
import { useNavigation } from "react-router-dom";

export const Otp = () => {
  const navigate = useNavigation();
  const [otp, setOtp] = useState("");

  // const backHandle = () => {

  // };
  return (
    <div className="main-div">
      <div className="back-div" onClick={() => navigate("/admin")}>
        <img src={left_arrow} alt="" style={{ width: 20, height: 20 }} />
        <h6>Back</h6>
      </div>
      <div className="login-div">
        <div className="login-title">
          <h2 className="font font-div-1">SMS Protal</h2>
          <p className="font font-div-2">
            Code Has been send to +91 913** ***32
          </p>
        </div>
        <div className="otp-div">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            // renderSeparator={<span>_</span>}
            renderInput={(props) => (
              <input
                {...props}
                style={{
                  width: 50,
                  height: 40,
                  marginLeft: 5,
                  borderRadius: 4,
                  backgroundColor: "#1f222a",
                  border: "none"
                }}
                // className="input-otp"
              />
            )}
            style={{ width: "50px", height: "40px" }}
            containerStyle={{ width: 50, height: 40 }}
          />
        </div>
        {/* <OTPInput
          value={otp}
          onChange={setOtp}
          autoFocus
          OTPLength={4}
          otpType="number"
          disabled={false}
          className="input-otp  "
        /> */}
        <button
          // onClick={getOtp}
          className="button"
          type="submit"
          variant="contained"
        >
          Login
        </button>
      </div>
    </div>
  );
};
