import React, { useMemo, useState } from "react";
import { Outlet, NavLink } from "react-router-dom";

//image
import notification from "../../../image/Notification.png";
import down_arrow from "../../../image/down-arrow.png";
import avtar from "../../../image/Avatar-1.png";

import "./page.css";
import Withid from "./smscode/Withid";
import Withoutid from "./smscode/Withoutid";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../Firebase";

const Smscode = () => {
  const [search, setSearch] = useState("");

  const withIDContent = useMemo(() => {
    return (
      <>
        <Withid search={search} />
      </>
    );
  }, [search]);
  const withoutIDContent = useMemo(() => {
    return (
      <>
        <Withoutid search={search} />
      </>
    );
  }, [search]);

  return (
    <div className="">
      <div>
        <div className="smscode-div">
          <div>
            <h2>Sms Code</h2>
          </div>
          <div className="smscode-div-1">
            <div>
              <input
                name="search"
                type="search"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder="Search anything here"
                className="div-input"
              />
            </div>
            <div className="smscode-div-2">
              <input type="date" className="div-input" />
              |
              <img
                src={notification}
                alt=""
                style={{ width: 24, height: 24 }}
              />
              |
              <div className="smscode-div-1 smscode-div-2">
                <h5 className="smscode-user">Daniel Lucas</h5>
                <img src={avtar} alt="" style={{ width: 24, height: 24 }} />
                <img
                  src={down_arrow}
                  alt=""
                  style={{ width: 16, height: 16 }}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <ul className="item">
            <li>
              <NavLink to="id"> with-id </NavLink>
            </li>
            <li>
              <NavLink to="withoutid"> without-id </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="content-div">
        <div className="sub-content-div">{withIDContent}</div>
        <div className="content-gap"></div>
        <div className="sub-content-div">{withoutIDContent}</div>
      </div>
    </div>
  );
};

export default Smscode;
