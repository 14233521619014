import React from "react";

//image
import mobile from "../../../image/mobile.png";
import sms from "../../../image/sms.png";
import global from "../../../image/global.png";

export const Notification = () => {
  return (
    <div>
      <div className="profile-div">
        <div className="sub-profile-div-1">
          <h3>General Notification</h3>
          <p>Select how you'll be notified when the following changes occur.</p>
        </div>
        <div className="sub-notification-div">
          <label>New Provider</label>
          <section className="notification-section">
            <option className="notification-option-1">Email</option>
            <option className="notification-option-2">Browser</option>
            <option className="notification-option-3">App</option>
          </section>
        </div>
        <hr />
        <div className="sub-notification-div">
          <label>New Customer</label>
          <section className="notification-section">
            <option className="notification-option-1">Email</option>
            <option className="notification-option-2">Browser</option>
            <option className="notification-option-3">App</option>
          </section>
        </div>
        <hr />
        <div className="sub-notification-div">
          <label>New device used to sign in</label>
          <section className="notification-section">
            <option className="notification-option-1">Email</option>
            <option className="notification-option-2">Browser</option>
            <option className="notification-option-3">App</option>
          </section>
        </div>
        <hr />
        <div className="sub-notification-div">
          <label>payment transaction</label>
          <section className="notification-section">
            <option className="notification-option-1">Email</option>
            <option className="notification-option-2">Browser</option>
            <option className="notification-option-3">App</option>
          </section>
        </div>
        <hr />
        <div className="sub-notification-div">
          <label>Reminders</label>
          <section className="notification-section">
            <option className="notification-option-1">Email</option>
            <option className="notification-option-2">Browser</option>
            <option className="notification-option-3">App</option>
          </section>
        </div>
        <hr />
        <div className="sub-button-div-1">
          <button
            style={{
              borderRadius: "0.5rem",
              padding: "6px 30px",
              backgroundColor: "#353A46",
            }}
          >
            Reset
          </button>
          <button style={{ borderRadius: "0.5rem", padding: "6px 30px" }}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};
