import { Outlet } from "react-router-dom";
// import Footer from "../components/Footer";
import Navbar from "../components/landingpage/Header";

function RootLayout() {
  return (
    <>
      <Navbar />
      <main className="" style={{marginTop:"5px", marginBottom: 3}}>
        <Outlet />
      </main>
    </>
  );
}

export default RootLayout;
