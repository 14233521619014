import React from "react";

import { Link } from "react-router-dom";

//image
import logo from "../../image/Group 3050.png";
import email_icon from "../../image/email-icon.png";
import phone_icon from "../../image/phone-icon.png";
import location_icon from "../../image/location-icon.png";
import instagram_icon from "../../image/instagram_Icons.png";
import twitter_icon from "../../image/twiter_Icons.png";
import _icon from "../../image/Icons-1.png";
import youtube_icon from "../../image/youtube_Icons.png";

import "../landingpage/home/home.css";

const Footer = () => {
  return (
    <div className="mt-2">
      <footer>
        <div className="footer">
          <div className="divided-top"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="footer_logo">
                  <img src={logo} alt="" style={{ width: 48.85, height: 40 }} />
                  <a className="navbar-brand brand " href="/">
                    Share OTP
                  </a>
                </div>
                <p className="w-75  my-5 text-sm-start">
                  Lorem ipsum dolor amet, consectetur adipiscing elit. Eget nisl
                  nunc quam ac sed turpis volutpat. Cursus sed massa non nisi,
                  placerat.
                </p>
                <div className="footer_social-media mt-5">
                  <img
                    src={instagram_icon}
                    alt=""
                    style={{ width: 30, height: 30 }}
                  />
                  <img src={_icon} alt="" style={{ width: 30, height: 30 }} />
                  <img
                    src={twitter_icon}
                    alt=""
                    style={{ width: 30, height: 30 }}
                  />
                  <img
                    src={youtube_icon}
                    alt=""
                    style={{ width: 30, height: 30 }}
                  />
                </div>
              </div>
              <div className="col col-lg-5 mt-5">
                <div className="footer-title footer-text ">Reach us</div>
                <div className="col p-0">
                  <div className="row">
                    <div className="footer-containt">
                      <img
                        src={email_icon}
                        alt=""
                        style={{ width: 25, height: 25 }}
                      />
                      <h6 style={{marginTop: '8px', textAlign: "left", fontSize: 12 }}>
                        management@shareotp.com
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="footer-containt">
                      <img
                        src={phone_icon}
                        alt=""
                        style={{ width: 25, height: 25 }}
                      />
                      <h6 style={{marginTop: '8px', textAlign: "left", fontSize: 12 }}>
                        +91 96723 19049
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="footer-containt">
                      <img
                        src={location_icon}
                        alt=""
                        style={{ width: 25, height: 25 }}
                      />
                      <p style={{marginTop: '8px', textAlign: "left", fontSize: 12 }}>
                        G-448 Tulsi arcade, near sudama chowk, mota-varachha surat, 394101 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <div className="mt-1 footer_link">
            <a href="/" className="footer_link-1">
              <div>© 2020 Scriptivaa PVT LTD. All rights reserved</div>
            </a>
            <div className="footer_link-div">
              <div>
                <Link className="footer-link-2" to="/Terms-condition">
                  Terms & Conditions
                </Link>
              </div>
              |
              <div>
                <Link className="footer-link-2" to="/Privacy-Policy">
                  Privacy Policy
                </Link>
              </div>
              |
              <div>
                <Link className="footer-link-2" to="/refund-policy">
                  Refund Policy
                </Link>
              </div> 
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
