import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import $ from "jquery";

//image
import block_image from "../../../image/creative-block.png";
import button from "../../../image/add-icon 2.png";
import ellipse from "../../../image/Ellipse.png";
import iphone from "../../../image/iPhone X.png";
import icon_1 from "../../../image/Icon-1.png";
import icon_2 from "../../../image/Icon-2.png";
import phone_image from "../../../image/phone-mockup.png";
import card from "../../../image/card.png";
import pic_customer from "../../../image/picture.png";
import pic_1 from "../../../image/1.png";
import phone_model from "../../../image/phone.png";
import right_arrow from "../../../image/right-arrow.png";
import phone_model_1 from "../../../image/phone-1.png";
import phone_model_2 from "../../../image/phone-2.png";
import model_1 from "../../../image/Frame 651.png";
import model_2 from "../../../image/Frame 652.png";
import model_3 from "../../../image/Frame 653.png";

//lottie
import how_it_works from "../../../lotties/how-it-works.json";

import "./home.css";
import Footer from "../Footer";
import { Contact } from "../Contact";

const Home = (props) => {
  const navigate = useNavigate();

  const defaultData = {
    loop: true,
    autoplay: true,
    animationData: how_it_works,
  };

  useEffect(() => {}, []);

  $(document).ready(function () {
    var contentSection = $(".scroal");
    var navigation = $("section");

    navigation.on("scroll", "a", function (event) {
      event.preventDefault();
      smoothScroll($(this.hash));
    });

    $(window).on("click", function () {
      updateNavigation();
    });
    updateNavigation();

    function updateNavigation() {
      contentSection.each(function () {
        var sectionName = $(this).attr("id");
        var navigationMatch = $('section a[href="#' + sectionName + '"]');
        if (
          $(this).offset().top - $(window).height() / 2 <
            $(window).scrollTop() &&
          $(this).offset().top + $(this).height() - $(window).height() / 2 >
            $(window).scrollTop()
        ) {
          navigationMatch.addClass(".show");
        } else {
          navigationMatch.removeClass(".show");
        }
      });
    }

    function smoothScroll(target) {
      $("body, html").animate(
        {
          scrollTop: target.offset().top,
        },
        800
      );
    }
  });

  return (
    <div className="main">
      <div className="hero_sectoin">
        <div className="group-1">
          <div className="heading">
            <h1 className="headingtext font-size text">
              Are you struggling to get OTP from your partner?
            </h1>
            <div className="buttondiv">
              <button className="Button" type="button">
                <img className="downloadButton" src={button} alt="" />
                Download App
              </button>
            </div>
          </div>
          <div className="block-image">
            <img
              src={block_image}
              alt=""
              className="img-thumbnail-1"
              style={{ alignItems: "center" }}
            />
          </div>
        </div>
        <div className="mask">
          <img className="iphone" src={iphone} alt="" />
          <img src={ellipse} className="ellipse" alt="" />
        </div>
      </div>
      {/* count section */}
      <div className="Statssection">
        <div className="subcontaint">
          <div className="subheading">
            <h5 className="subtitle text font-size-1">
              Some count that matters
            </h5>
            <p className="sub_description font-size-3 text">
              Our achievement in the journey depicted in numbers
            </p>
          </div>
          <div className="count">
            <div className="subrow-1">
              <img className="icon_1" src={icon_1} alt="" />
              <div className="sub_count">
                <h5 className="sub_count-title text">1 Cr+</h5>
                <p className="sub_count-label font-size-2 text">
                  Number of OTP Shared
                </p>
              </div>
            </div>
            <div className="subrow-2">
              <img src={icon_2} alt="" className="icon_1" />
              <div className="sub_count">
                <h5 className="sub_count-title text">1 M+</h5>
                <p className="sub_count-label font-size-2 text">
                  Number Of Users
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* loattie section */}
      <div className="lottie_section">
        <h3 className="lottie_title text font-size-1">How it Works</h3>
        <div className="sub_lottie-section">
          <Lottie options={defaultData} />
        </div>
      </div>

      {/* share section */}
      <div className="share_section">
        <div className="sub_share-section sub-share">
          <div className="scroal">
            <div className="mobile-section-1" id="sub-1">
              <img
                src={phone_image}
                loading="lazy"
                alt=""
                className="sub_mobile-section"
              />
            </div>
            <div className="mobile-section-2" id="sub-2">
              <img
                src={phone_image}
                loading="lazy"
                alt=""
                className="sub_mobile-section-1"
              />
            </div>
            <div className="mobile-section-3" id="sub-3">
              <img
                src={phone_image}
                loading="lazy"
                alt=""
                className="sub_mobile-section-2"
              />
            </div>
          </div>
          <section className="sub_share-text">
            <div className="sub-text-colunm">
              <div className="sub-text-content">
                <a className="subs-section-1 " href="#sub-1">
                  {/* <span className="show" /> */}
                  <img
                    src={model_1}
                    alt=""
                    width={450}
                    loading="lazy"
                    className="img-1"
                  />
                </a>
                <a className="subs-section-2" href="#sub-2">
                  {/* <span className="show" /> */}
                  <img
                    src={model_2}
                    alt=""
                    width={500}
                    loading="lazy"
                    className="img-2"
                  />
                </a>
                <a className="subs-section-3" href="#sub-3">
                  {/* <span className="show" /> */}
                  <img
                    src={model_3}
                    alt=""
                    width={450}
                    loading="lazy"
                    className="img-3"
                  />
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* customer section and api intigrat feature */}
      <div className="customer_section">
        <div className="sub_customer-heading">
          <h3 className="sub_customer-title text font-size-5">
            Our Customer Speak
          </h3>
          <p className="sub_customer-description text font-size-3">
            We have been working with clients around the world
          </p>
        </div>
        <div className="sub_customer-review">
          <div className="sub_costomer-1">
            <img src={card} alt="" className="card_review" />
            <h6 className="card-title">Efficient Collaborating</h6>
            <p className="card-description text font-size-4 ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor
              neque sed imperdiet nibh lectus
            </p>
            <div className="sub_customer">
              <img
                src={pic_customer}
                alt=""
                style={{ width: 60, height: 60, marginBottom: 5 }}
              />
              <div className="sub_customer-text">
                <h5>Jane Cooper</h5>
                <p style={{ fontSize: 12 }}>CEO at ABC Corporation</p>
              </div>
            </div>
          </div>
          <div className="sub_costomer-2">
            <img src={card} alt="" className="card_review" />
            <h6 className="card-title">Intuitive Design</h6>
            <p className="card-description text font-size-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor
              neque sed imperdiet nibh lectus
            </p>
            <div className="sub_custome-a">
              <img
                src={pic_customer}
                style={{ width: 60, height: 60, marginBottom: 5 }}
                alt=""
              />
              <div className="sub_customer-text">
                <h5>Jane Cooper</h5>
                <p style={{ fontSize: 13 }}>CEO at ABC Corporation</p>
              </div>
            </div>
          </div>
          <div className="sub_costomer-3">
            <img src={card} alt="" className="card_review" />
            <h6 className="card-title">Mindblowing Service</h6>
            <p className="card-description text font-size-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor
              neque sed imperdiet nibh lectus
            </p>
            <div className="sub_customer-b ">
              <img
                src={pic_customer}
                alt=""
                style={{ width: 60, height: 60, marginBottom: 5 }}
              />
              <div className="sub_customer-text">
                <h5>Jane Cooper</h5>
                <p style={{ fontSize: 12 }}>CEO at ABC Corporation</p>
              </div>
            </div>
          </div>
        </div>
        <img src={pic_1} alt="" className="customer_scroll" />
      </div>

      {/* about-section */}
      <div className="about_section">
        <img src={phone_model} alt="" className="about_image" />
        <div className="about_section-text">
          <h2 className="about_title text font-size-1">About us</h2>
          <p className="about_description text font-size-2">
            Scriptinaa Private Limited is a software company that provides
            innovative product-based services and service-based IT services to
            various industries. The company was founded in 2023 and is
            headquartered in surat.
          </p>
          <div className="about-more">
            <h6 className="about-more-text text font-size-2">Learn More</h6>
            <img
              src={right_arrow}
              alt=""
              onClick={() => navigate("/about")}
              className="about-more-arrow"
            />
          </div>
        </div>
      </div>
      {/* manage-section */}
      <div className="manage_section">
        <div className="manage_section-text">
          <div className="sub_manage_section-text">
            <h2 className="manage_section-heading text font-size-1">
              Manage your OTP from Our App
            </h2>
            <p className="manage_section-descripion text font-size-2">
              Download the app to manage your projects, keep track of the
              progress and complete tasks without procastinating. Stay on track
              and complete on time!
            </p>
          </div>
          <div className="manager_section-button">
            <h6 className="text font-size-4">Get the App</h6>
            <button className="Button" type="button">
              <img className="downloadButton" src={button} alt="" />
              Download App
            </button>
          </div>
        </div>
        <img src={phone_model_1} alt="" className="manage_section-model-1" />
        <img src={phone_model_2} alt="" className="manage_section-model-2" />
      </div>

      {/* feedback-section */}
      <div className="feedback_section" id={props.id}>
        <div className="feedback_section-heading">
          <h2 className="sub_feedback_section-heading text font-size-1">
            Get In Touch
          </h2>
          <p className="sub_feedback_section-description text font-size-3">
            Use the form below to get in touch. Our office hours are Monday
            through Friday, 9 am to 5 pm GMT, and we attempt to respond to
            support requests within 1 business day.
          </p>
        </div>
        <div>
          <Contact />
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Home;
