import React from "react";

import products from "../../../image/products.png";
import service from "../../../image/services.png";
import mission from "../../../image/mission.png";
import vision from "../../../image/vision.png";
import team from "../../../image/team.png";
import contact from "../../../image/contact-us.png";

import Footer from "../Footer";
// import our_mission from "../../image/our-mission.jpeg";
// import our_vision from "../../image/our-vision.jpeg";
// import our_team from "../../image/join-our.jpeg";
// import contact from "../../image/contact-us.jpeg";

const index = () => {
  return (
    <div className="about_main">
      <div className="about_main-sub_div">
        <div className="about_main-title_text">
          <h1 className="about-text font-size text">About Us</h1>
        </div>
        <div className="about_div-1">
          <div className="about_div-1-image">            
              <img
                src={products}
                alt=""
                className=" about-image-1 about_image-1"
              />           
          </div>
          <div className="about_div-1-text">
            <h4 className="text font-size-5 about_title-1">Our Products</h4>
            <p className="text font-size-3 about_description-1">
              Scriptinaa Private Limited offers a range of products, including
              ShareOTP. ShareOTP is a peer-to-peer OTP sharing mobile
              application that enables users to share OTPs securely with friends
              and family. KnockSense is an advanced visitor management system
              that helps businesses manage visitors efficiently.
            </p>
          </div>
        </div>
        <div className="divided"></div>
        <div className="about_div-2">
          <div className="about_div-2-text">
            <h4 className="text font-size-5 about_title-1 about_title-2">Our Services</h4>
            <p className="about_description-1 about_description-2 text font-size-3">
              We provide a range of IT services, including web and mobile
              application development, custom software development, and IT
              consulting services. Our team of experienced professionals is
              committed to delivering high-quality services that meet our
              clients’ specific needs.
            </p>
          </div>
          <div className="about_div-2-image">         
              <img
                src={service}
                alt=""
                className=" about-image-2 about_image-1"
              />          
          </div>
        </div>
        <div className="divided"></div>
        <div className="about_div-1">
        <div className="about_div-1-image">            
              <img
                src={mission}
                alt=""
                className="about-image-1 about_image-1"
              />           
            </div>
          <div className="about_div-1-text">
            <h4 className="text font-size-5 about_title-1">Our Mission</h4>
            <p className="text font-size-3 about_description-1">
              At Scriptinaa Private Limited, our mission is to provide
              innovative and reliable technology solutions that help our clients
              achieve their business goals. We strive to deliver value to our
              clients by understanding their needs and providing customised
              solutions that exceed their expectations.
            </p>
          </div>
        </div>
       
        <div className="about_div-2">
          <div className="about_div-2-text">
            <h4 className="text font-size-5 about_title-1 about_title-2">Our Vision</h4>
            <p className="about_description-1 text font-size-3 about_description-2">
              Our vision is to become a leading provider of innovative
              technology solutions that enable businesses to achieve their full
              potential. We aim to achieve this by constantly innovating and
              adapting to changing industry trends and customer needs.
            </p>
          </div>
          <div className="about_div-2-image">
              <img
                src={vision}
                alt=""
                className="about-image-2 about_image-1"
              />         
          </div>
        </div>
        
        <div className="about_div-1">
        <div className="about_div-1-image">           
              <img
                src={team}
                alt=""
                className="about-image-1 about_image-1"            
              />           
            </div>
          <div className="about_div-1-text">
            <h4 className="text font-size-5 about_title-1">Our Team</h4>
            <p className="text font-size-3 about_description-1">
              Our team consists of highly skilled and experienced professionals
              who are committed to delivering exceptional service to our
              clients. We believe that our team’s dedication and expertise are
              the key to our success.
            </p>
          </div>
        </div>
        <div className="about_div-2">
          <div className="about_div-2-text ">
            <h4 className="text font-size-5 about_title-1 about_title-2">Contact Us</h4>
            <p className="text font-size-3 about_description-1 about_description-2">
              If you have any questions about our products or services, please
              contact us at management.shareotp@gmail.com. Thank you for
              considering Scriptinaa Private Limited as your technology partner.
            </p>
          </div>
          <div className="about_div-2-image">      
              <img
                src={contact}
                alt=""
                className=" about-image-2 about_image-1"
              />          
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default index;
