import React from "react";

//image
import notification from "../../../image/Notification.png";
import down_arrow from "../../../image/down-arrow.png";
import avtar from "../../../image/Avatar-1.png";

import "../adminHome/adminHome.css";

const Dashboard = () => {
  return (
    <div className="container">
      <div className="dashboard-div">
        <div>
          <h2>Dashboard</h2>
        </div>
        <div className="dashboard-div-1">
          <input type="date" className="div-input"/>|{" "}
          <img src={notification} alt="" style={{ width: 24, height: 24 }} />|{" "}
          <div className="dashboard-div-1 dashboard-div-2">
            <h5 className="dashboard-user">Daniel Lucas</h5>
            <img src={avtar} alt="" style={{ width: 24, height: 24 }} />
            <img src={down_arrow} alt="" style={{ width: 16, height: 16 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
