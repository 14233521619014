import React from "react";

// image
import monitor from "../../../image/monitor.png";
import mobile from "../../../image/mobile.png";

export const Password = () => {
  return (
    <div>
      <div className="profile-div">
        <div className="sub-profile-div-1">
          <h3>Password</h3>
          <p>Enter your current & new password to reset your password</p>
        </div>
        <div className="sub-profile-div-2">
          <label>Current Password</label>
          <input placeholder="Current Password" className="profile-input " />
        </div>
        <hr />
        <div className="sub-profile-div-2">
          <label>New Password</label>
          <input
            placeholder="  New Password  "
            className="profile-input password-div"
          />
        </div>
        <hr />
        <div className="sub-profile-div-2">
          <label>Confirm Password</label>
          <input placeholder="Confirm Password" className="profile-input " />
        </div>
        <hr />
        <div className="sub-button-div-1">
          <button
            style={{
              borderRadius: "0.5rem",
              padding: "6px 30px",
              backgroundColor: "#353A46",
            }}
          >
            Reset
          </button>
          <button style={{ borderRadius: "0.5rem", padding: "6px 40px" }}>
            Update Password
          </button>
        </div>

        <div className="sub-password-div">
          <div className="sub-password-div-1">
            <h5>Where you're signed in</h5>
            <p className="sub-password-div-p">You're signed in to your account on these devices.</p>
          </div>
          <div className="sub-password-div-2">
            <div className="sub-password-div-2-1">
              <div>
                <img src={monitor} alt="" style={{ width: 40, height: 44, paddingTop: '0.4rem' }} />
              </div>
              <div className="sub-password-div-text">
                <h6>Desktop FKL-278</h6>
                <p className="sub-password-div-p">Manhattan, United State • 09-Mar-2022, 03:02 PM</p>
              </div>
            </div>
            <hr className="hr-div" />
            <div className="sub-password-div-2-1">
              <div >
                <img src={mobile} alt="" style={{ width: 40, height: 40 , paddingTop: '0.4rem'}} />
              </div>
              <div className="sub-password-div-text">
                <h6>iPhone 13 Pro Max</h6>
                <p className="sub-password-div-p">Manhattan, United State • 04-Mar-2022, 05:45 PM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
