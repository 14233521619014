import React from "react";

//image
import notification from "../../../image/Notification.png";
import down_arrow from "../../../image/down-arrow.png";
import avtar from "../../../image/Avatar-1.png";
import avtar_a from "../../../image/Type-Avatar.png";
import vector from "../../../image/Vector.png";
import elips from "../../../image/Ellipse 27.png";
import elips_1 from "../../../image/Ellipse 29.png";
import elips_2 from "../../../image/Ellipse-1.png";

import "../page/page.css";

export const Transection = () => {
  return (
    <div>
      <div className="smscode-div">
        <div>
          <h2>Transection</h2>
        </div>
        <div className="smscode-div-1 smscode-div-2">
          <input type="date" className="div-input" />
          |
          <img src={notification} alt="" style={{ width: 24, height: 24 }} />|
          <div className="smscode-div-1 smscode-div-2">
            <h5 className="smscode-user">Daniel Lucas</h5>
            <img src={avtar} alt="" style={{ width: 24, height: 24 }} />
            <img src={down_arrow} alt="" style={{ width: 16, height: 16 }} />
          </div>
        </div>
      </div>
      <div className="transection-div">
        <div className="transection-div-main-p">
          <h4>Transaction History</h4>
          <p className="transection-div-p">
            Enter your current & new password to reset your password
          </p>
        </div>
        <input type="search" placeholder="Search" className="div-input" />
      </div>
      <div className="container mt-2">
        <table className="">
          <thead>
            <tr style={{ color: "white", backgroundColor: "#303542" }}>
              <th>
                NAME{" "}
                <span>
                  <img src={vector} alt="" style={{ width: 12, height: 13 }} />
                </span>
              </th>
              <th>
                DATE{" "}
                <span>
                  <img src={vector} alt="" style={{ width: 12, height: 13 }} />
                </span>
              </th>
              <th>
                Amount{" "}
                <span>
                  <img src={vector} alt="" style={{ width: 12, height: 13 }} />
                </span>
              </th>
              <th>
                OS Platfrom{" "}
                <span>
                  <img src={vector} alt="" style={{ width: 12, height: 13 }} />
                </span>
              </th>
              <th>
                Transaction ID{" "}
                <span>
                  <img src={vector} alt="" style={{ width: 12, height: 13 }} />
                </span>
              </th>
              <th>
                STATUS{" "}
                <span>
                  <img src={vector} alt="" style={{ width: 12, height: 13 }} />
                </span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ color: "white" }} className="tbody-div">
              <td>
                <img src={avtar_a} alt="" style={{ width: 40, height: 40 }} />{" "}
                Keval Pavasiya
              </td>
              <td>08/10/2023</td>
              <td>4000 USD</td>
              <td>Android</td>
              <td>3CW48RCC718TCDHV</td>
              <td className="transection-status-2">
                <span>
                  <img src={elips_2} alt="" style={{ width: 10 }} />
                </span>{" "}
                {""} Pending
              </td>
              <td>
                <button className="customer-div-button">View</button>
              </td>
            </tr>
            <tr style={{ color: "white" }} className="tbody-div">
              <td>
                <img src={avtar_a} alt="" style={{ width: 40, height: 40 }} />{" "}
                Keval Pavasiya
              </td>
              <td>08/10/2023</td>
              <td>4000 USD</td>
              <td>Android</td>
              <td>3CW48RCC718TCDHV</td>
              <td className="transection-status">
                {" "}
                <span>
                  <img src={elips} alt="" style={{ width: 10 }} className="tbody-div" />
                </span>{" "}
                Complete
              </td>
              <td>
                <button className="customer-div-button">View</button>
              </td>
            </tr>
            <tr style={{ color: "white" }}  className="tbody-div">
              <td>
                <img src={avtar_a} alt="" style={{ width: 40, height: 40 }} />{" "}
                Keval Pavasiya
              </td>
              <td>08/10/2023</td>
              <td>4000 USD</td>
              <td>Android</td>
              <td>3CW48RCC718TCDHV</td>
              <td className="transection-status-1">
                <span>
                  <img src={elips_1} alt="" style={{ width: 10 }} />
                </span>{" "}
                Canceled
              </td>
              <td>
                <button className="customer-div-button">View</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
