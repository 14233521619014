import React from "react";

import { getRefund } from "../../api/policy";
import { useQuery } from "react-query";
import Footer from "../landingpage/Footer";
import Navbar from "../landingpage/Header";

export const Refundpolicy = () => {

  const { data: refundData } = useQuery("refund", getRefund);

  console.log(refundData);
  return (
    <div className="grid gap-0 row-gap-4">
      <div className="policy-1">
      <div className="g-col-6">
        <Navbar />
      </div>
      <div className="mt-5 g-col-6 p-3 bg-dark">
        <header className="mt-5 ">
          <h1 className="position-relative top-0 bg-dark p-3 font-size">
            Refund Policy
          </h1>
        </header>
        <div style={{ marginBottom: 12 }}>
          {refundData &&
            refundData?.policy?.map((data) => (
              <div
                key={data._id}
                className="contianer position-relative bg-dark m-0 pt-3"
              >
                <h3 className=" text-md-start ms-5">{data.title}</h3>
                <ul className=" text-start m-0 ">
                  <div className="mt-1 mx-5">
                    {data.discription.map((item, index) => (
                      <li key={index} className="pt-2">
                        {index + 1}. {item}
                      </li>
                    ))}
                  </div>
                </ul>
              </div>
            ))}
        </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
