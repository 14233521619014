// import adminHome from "./components/adminHome/index";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import AdminHome from "./components/admin/adminHome/index";

import Login from "./components/admin/login/index";
import Provider from "./components/admin/page/Provider";
import Smscode from "./components/admin/page/Smscode";
import Withid from "./components/admin/page/smscode/Withid";
import Withoutid from "./components/admin/page/smscode/Withoutid";
import About from "./components/landingpage/about/index";
import Home from "./components/landingpage/home/index";
import RootLayout from "./components/Root";
import Privecy from "./components/policy/Privecy";
import Terms from "./components/policy/Terms";
import Dashboard from "./components/admin/page/Dashboard";
import { Refundpolicy } from "./components/policy/Refundpolicy";
import { Customer } from "./components/admin/page/Customer";
import { Transection } from "./components/admin/page/Transection";
import { Setting } from "./components/admin/page/Setting";
import { Profile } from "./components/admin/page/Profile";
import { Password } from "./components/admin/page/Password";
import { Notification } from "./components/admin/page/Notification";

const route = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/About", element: <About /> },
    ],
  },
  { path: "/privacy-policy", element: <Privecy /> },
  { path: "/terms-condition", element: <Terms /> },
  { path: "/refund-policy", element: <Refundpolicy /> },
  {
    path: "/admin",
    element: <Login />,
  },
  {
    path: "/admin/home",
    element: <AdminHome />,
    children: [
      { path: "*", index: true, element: <Dashboard /> },
      {
        path: "/admin/home/smscode",
        element: <Smscode />,
        children: [
          { path: ":id", element: <Withid /> },
          { path: "withoutid", element: <Withoutid /> },
        ],
      },
      { path: "/admin/home/provider", element: <Provider /> },
   {
    path: "/admin/home/customer", element:<Customer/>
   },
   {
    path: "/admin/home/transectin", element:<Transection/>
   },
   {
    path: "/admin/home/setting", element:<Setting/>, children:[
      {path: "*", index: true, element:<Profile/>},
      { path: "password", element: <Password/>},
      { path: "notification", element: <Notification/>},
    ]
   },
    ],
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={route} />
    </div>
  );
}

export default App;
