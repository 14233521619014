import { Avatar } from "@mui/material";
import React from "react";

//image
import avtar from "../../../image/Type-Avatar.png";

export const Profile = () => {
  return (
    <div>
      <div className="profile-div">
        <div className="sub-profile-div-1">
          <h3>General</h3>
          <p className="transection-div-p">Basic info, like your name that will displayed </p>
        </div>
        <hr />
        <div className="sub-profile-div-2">
          <label>Name</label>
          <input placeholder="Daniel Lucas" className="profile-input sub-profile-input-div-1" />
        </div>
        <hr />
        <div className="sub-profile-div-2">
          <label>Email</label>
          <input placeholder="Daniel_vergas@infotech.io" className="profile-input sub-profile-input-div-2" />
        </div>
        <hr />
        <div className="sub-profile-div-2">
          <label>Avatar</label>
          <Avatar src={avtar} />
        </div>
        <hr />
        <div className="sub-button-div-1">
          <button
            style={{
              borderRadius: "0.5rem",
              padding: "6px 30px",
              backgroundColor: "#353A46",
            }}
          >
            Reset
          </button>
          <button style={{ borderRadius: "0.5rem", padding: "6px 30px" }}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};
