import client from "../utils/client";

export async function getTerms() {
  const { data } = await client.get("/v1/policy/terms");
  return data.data[0];
}

export async function getPrivacy() {
  const { data } = await client.get("/v1/policy/privacy");
  return data.data[0];
}

export async function getRefund() {
  const { data } = await client.get("/v1/policy/refund");
  return data.data[0];
}
