import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./home/home.css";

export const Contact = () => {
  const form = useRef();
  const [verify, setVerify] = useState(false);
  // const [name, setName]=useState('')

  const onChange = (value) => {
    console.log("Captcha value:", value);
    setVerify(true);
  };

  const sendEmail = (event) => {
    event.preventDefault();
    emailjs
      .sendForm(
        "service_0qhxeoh",
        "template_j86zdz9",
        form.current,
        "iPVTFUZxq_mBAIIcN"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast("send successfull");
          toast.success();
        },
        (error) => {
          console.log(error.text);
          toast.error();
        }
      );
    event.target.reset();
  };

  return (
    <div id="#contact-us">
      <form ref={form} onSubmit={sendEmail}>
        <div className="feedback_section-form">
          <div className="feedback_section-form-1">
            <input
              className="feedback_section-form-input"
              placeholder="Name"
              type="text"
              name="user_name"
              required
            />
            <input
              className="feedback_section-form-input"
              placeholder="Email Address"
              type="email"
              name="user_email"
              required
            />
          </div>
          <div className="feedback_section-form-1">
            <input
              className="feedback_section-form-input"
              placeholder="Subject"
              type="text"
              name="user_subject"
              required
            />
            <input
              className="feedback_section-form-input"
              placeholder="Phone Number"
              type="number"
              name="user_number"
            />
          </div>
          <div>
            <textarea
              className="feedback_section-form-textarea"
              placeholder="Your Message"
              name="user_message"
            />
          </div>
          <div className="feedback_section-form-1">
            <ReCAPTCHA
              sitekey="6LeiQIclAAAAAEXTFKYsgIhYrjfV2TZCkFoJ5P1O"
              onChange={onChange}
            />
            <div>
              <button
                className="feedback_section-form-button"
                type="submit"
                disabled={!verify}
              >
                Send
              </button>
              <ToastContainer />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
